import type { Scene, User, Video, VideoSimple } from "@/api.d";

import { useAuth0 } from "@auth0/auth0-vue";

export const API_BASE_URL = `${import.meta.env.VITE_API_BASE_URL}/v1`;

let token = "";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function fetchProtected(url: string, options: any = {}): Promise<Response> {
  // TODO: check this
  if (!token) {
    const { getAccessTokenSilently } = useAuth0();
    token = await getAccessTokenSilently();
  }

  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: "Bearer " + token,
    },
  });
}

export async function getGuestVideo(videoId: string): Promise<Video> {
  const url = `${API_BASE_URL}/gvideos/${videoId}`;
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export function getUploadUrl(authenticate?: boolean): string {
  return authenticate ? `${API_BASE_URL}/videos` : `${API_BASE_URL}/gvideos`;
}

export async function joinWaitingList(email: string, comments?: string, guest_id?: string): Promise<void> {
  const url = `${API_BASE_URL}/waiting-list`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, comments, guest_id }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function regenerateGuestVideo(videoId: string): Promise<Video> {
  const url = `${API_BASE_URL}/gvideos/${videoId}/regenerate`;
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function generateVideo(videoId: string, data: object): Promise<Video> {
  const url = `${API_BASE_URL}/videos/${videoId}/generate`;
  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function contact(message: string): Promise<void> {
  const url = `${API_BASE_URL}/contact`;
  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ message }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getCurrentUser(): Promise<User> {
  const url = `${API_BASE_URL}/me`;
  const response = await fetchProtected(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getVideo(videoId: string): Promise<Video> {
  const url = `${API_BASE_URL}/videos/${videoId}`;
  const response = await fetchProtected(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getVideos(): Promise<VideoSimple[]> {
  const url = `${API_BASE_URL}/videos`;
  const response = await fetchProtected(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function updateVideo(videoId: string, data: object): Promise<Video> {
  const url = `${API_BASE_URL}/videos/${videoId}`;
  const response = await fetchProtected(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function sendFullVideoFeedback(videoId: string, comment: string): Promise<Video> {
  const url = `${API_BASE_URL}/videos/${videoId}/feedback`;
  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ comment }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function createScenes(videoId: string): Promise<Scene[]> {
  const url = `${API_BASE_URL}/videos/${videoId}/scenes`;
  const response = await fetchProtected(url, {
    method: "POST",
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getScenes(videoId: string): Promise<Scene[]> {
  const url = `${API_BASE_URL}/videos/${videoId}/scenes`;
  const response = await fetchProtected(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function generateSceneClip(videoId: string, sceneId: string, data: object): Promise<Scene> {
  const url = `${API_BASE_URL}/videos/${videoId}/scenes/${sceneId}/generate`;
  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function regenerateSceneImage(videoId: string, sceneId: string, data: object): Promise<Scene> {
  const url = `${API_BASE_URL}/videos/${videoId}/scenes/${sceneId}/regenerate`;
  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function updateScene(videoId: string, sceneId: string, data: object): Promise<Scene> {
  const url = `${API_BASE_URL}/videos/${videoId}/scenes/${sceneId}`;
  const response = await fetchProtected(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}
