<script setup lang="ts">
const reloadPage = () => {
  location.reload();
};
</script>

<template>
  <div class="mx-auto w-72">
    <Message severity="error"> There's been an error loading the page. Please try again. </Message>
    <div class="mt-5 text-center">
      <Button label="Reload" icon="pi pi-refresh" size="small" @click="reloadPage" />
    </div>
  </div>
</template>
