<script setup lang="ts">
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import * as Sentry from "@sentry/browser";
import { computed, watchEffect } from "vue";
import { useRoute } from "vue-router";

const { isAuthenticated, isLoading, user } = useAuth0();
const route = useRoute();

const layout = computed(() => {
  return route.meta.layout || DefaultLayout;
});

watchEffect(() => {
  if (!isLoading.value) {
    Sentry.setUser(isAuthenticated.value ? { id: user.value?.sub, email: user.value?.email } : null);
  }
});
</script>

<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
