<script setup lang="ts">
import { useRoute } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";
import Footer from "@/components/common/Footer.vue";
import Loading from "@/components/common/Loading.vue";
import TopBar from "@/components/landing/TopBar.vue";

const { isLoading } = useAuth0();
const route = useRoute();
</script>

<template>
  <div class="min-h-screen flex flex-col">
    <TopBar />

    <div class="flex-grow">
      <Loading v-if="!route.matched.length && isLoading" />
      <router-view />
    </div>

    <Footer />
  </div>
</template>
