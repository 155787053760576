import { createAuthGuard, useAuth0 } from "@auth0/auth0-vue";
import type { App } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import type { Router } from "vue-router";

import DashboardLayout from "./layouts/DashboardLayout.vue";
import SimpleLayout from "./layouts/SimpleLayout.vue";
import VideoLayout from "./layouts/VideoLayout.vue";

export function createAppRouter(app: App): Router {
  const authGuard = createAuthGuard(app);

  return createRouter({
    history: createWebHistory(),
    routes: [
      { path: "/", name: "home", component: () => import("./views/landing/Home.vue") },
      {
        path: "/dashboard",
        name: "dashboard",
        redirect: "/dashboard/videos",
        beforeEnter: authGuard,
        meta: { layout: DashboardLayout },
        children: [
          { path: "help", name: "help", component: () => import("./views/dashboard/Help.vue") },
          { path: "account", name: "account", component: () => import("./views/dashboard/Account.vue") },
          {
            path: "videos/create",
            name: "video-create",
            component: () => import("./views/dashboard/video/VideoCreate.vue"),
          },
          {
            path: "videos/:id",
            name: "video",
            redirect: (to) => `/dashboard/videos/${to.params.id}/customize`,
            meta: { layout: VideoLayout },
            children: [
              {
                path: "customize",
                name: "video-customize",
                component: () => import("./views/dashboard/video/VideoCustomizeStep.vue"),
                meta: { noFooter: true },
              },
              {
                path: "scenes",
                name: "video-scenes",
                component: () => import("./views/dashboard/video/VideoScenesStep.vue"),
                meta: { noFooter: true },
              },
              {
                path: "generate",
                name: "video-generate",
                component: () => import("./views/dashboard/video/VideoGenerateStep.vue"),
                meta: { noFooter: true },
              },
              {
                path: "download",
                name: "video-download",
                component: () => import("./views/dashboard/video/VideoDownloadStep.vue"),
              },
            ],
          },
          { path: "videos", name: "videos", component: () => import("./views/dashboard/Videos.vue") },
        ],
      },
      {
        path: "/invite",
        name: "invite",
        component: () => import("./views/landing/Invite.vue"), // shows while loading
        beforeEnter: async () => {
          const { loginWithRedirect } = useAuth0();
          await loginWithRedirect();
          return { path: "/dashboard" };
        },
      },
      // { path: "/pricing", component: () => import("./views/Pricing.vue") },
      { path: "/privacy-policy", name: "privacy", component: () => import("./views/landing/PrivacyPolicy.vue") },
      { path: "/sign-in", name: "sign-in", component: () => import("./views/landing/SignIn.vue") },
      { path: "/terms-of-service", name: "tos", component: () => import("./views/landing/TermsOfService.vue") },
      {
        path: "/:pathMatch(.*)*",
        component: () => import("./views/errors/NotFound.vue"),
        meta: { layout: SimpleLayout },
      },
    ],
    scrollBehavior(_to, _from, savedPosition) {
      const mainElement = document.querySelector("main");
      if (mainElement) {
        mainElement.scrollTo({ top: 0, left: 0 });
      } else if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0, left: 0 };
      }
    },
  });
}
