import "vanilla-cookieconsent/dist/cookieconsent.css";

import * as CookieConsent from "vanilla-cookieconsent";
import type { App } from "vue";

const config = {
  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
    },
    analytics: {},
  },

  language: {
    default: "en",
    translations: {
      en: {
        consentModal: {
          title: "Cookie Usage",
          description:
            "This site uses essential cookies to properly operate and tracking cookies—disabled by default—to understand how you interact with the site.",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          showPreferencesBtn: "Manage preferences",
        },
        preferencesModal: {
          title: "Manage cookie preferences",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          savePreferencesBtn: "Accept current selection",
          closeIconLabel: "Close modal",
          sections: [
            {
              title: "Cookie Usage",
              description:
                "We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.!",
            },
            {
              title: "Strictly Necessary cookies",
              description:
                "These cookies are essential for the proper functioning of the website and cannot be disabled.",
              linkedCategory: "necessary",
            },
            {
              title: "Performance and Analytics",
              description:
                "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
              linkedCategory: "analytics",
            },
            {
              title: "More information",
              description:
                'For any queries in relation to my policy on cookies and your choices, please <a href="mailto:contact@lyricedits.ai">contact us</a>',
            },
          ],
        },
      },
    },
  },
};

export default {
  install: (app: App) => {
    app.config.globalProperties.$CookieConsent = CookieConsent;
    app.config.globalProperties.$CookieConsent.run(config);
  },
};
