<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import DarkModeToggler from "@/components/common/DarkModeToggler.vue";
import TopBar from "@/components/common/TopBar.vue";

const { isAuthenticated } = useAuth0();
</script>

<template>
  <TopBar>
    <DarkModeToggler />
    <Button v-if="isAuthenticated" as="router-link" size="small" :to="{ name: 'dashboard' }">
      <div>
        <span class="hidden sm:inline">Go to</span>
        Dashboard
      </div>
    </Button>
    <Button v-else as="router-link" label="Sign In" size="small" severity="secondary" :to="{ name: 'sign-in' }" />
  </TopBar>
</template>
