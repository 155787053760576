import * as Sentry from "@sentry/browser";

import { generateVideo, getVideo, sendFullVideoFeedback, updateVideo } from "@/api";
import type { Video, VideoConfig } from "@/api.d";
import { VIDEO_STATUS_ERROR, VIDEO_STATUS_LYRICS_EXTRACTED, VIDEO_STATUS_UPLOADED } from "@/utils";
import { computed, ref } from "vue";

import { useCustomizeSidebarStore } from "@/stores/customizeSidebar";
import { defineStore } from "pinia";
import { useToast } from "primevue/usetoast";

export const useVideoStore = defineStore("video", () => {
  const { resetPanels } = useCustomizeSidebarStore();
  const toast = useToast();

  const video = ref<Video>();

  const isRequesting = ref(false);
  const loadingError = ref(false);

  const isPortrait = computed(() => video.value?.aspect_ratio === "portrait");
  const statusIsError = computed(() => video.value?.status === VIDEO_STATUS_ERROR);
  const statusIsGenerating = computed(() => video.value?.queued || video.value?.processing);

  const displayError = (message: string): void => {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  };

  const displaySuccess = (message: string): void => {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const generateFull = async (): Promise<void> => {
    if (!video.value) return;

    toast.removeAllGroups();

    // Don't wait for response, show changes right away
    video.value = {
      ...video.value,
      full_generation: true,
      queued: true,
    };

    try {
      await generateVideo(video.value.id, { full: true });
    } catch (error) {
      displayError("There was an error generating the video. Please try again.");
      Sentry.withScope(function (scope) {
        scope.setTag("video_id", video.value?.id);
        Sentry.captureException(error);
      });
    }
  };

  const generatePreview = async (config: Partial<VideoConfig>): Promise<void> => {
    if (!video.value) return;

    toast.removeAllGroups();

    const languageChange = config.language !== undefined;

    // Don't wait for response, show changes right away
    video.value = {
      ...video.value,
      ...config,
      status: languageChange ? VIDEO_STATUS_UPLOADED : VIDEO_STATUS_LYRICS_EXTRACTED,
      queued: true,
    };

    try {
      await generateVideo(video.value.id, config);
    } catch (error) {
      displayError("There was an error generating the video. Please try again.");
      Sentry.withScope(function (scope) {
        scope.setTag("video_id", video.value?.id);
        Sentry.captureException(error);
      });
    }
  };

  const loadVideo = async (id: string): Promise<void> => {
    isRequesting.value = true;
    loadingError.value = false;
    video.value = undefined;

    resetPanels();

    try {
      const response = await getVideo(id);
      video.value = response;
    } catch (error) {
      loadingError.value = true;
      Sentry.withScope(function (scope) {
        scope.setTag("video_id", id);
        Sentry.captureException(error);
      });
    }

    isRequesting.value = false;
  };

  const refresh = async (): Promise<void> => {
    if (isRequesting.value || !video.value) {
      return;
    }

    isRequesting.value = true;

    try {
      const response = await getVideo(video.value.id);
      video.value = response;
    } catch (error) {
      // TODO: retry on error
      Sentry.withScope(function (scope) {
        scope.setTag("video_id", video.value?.id);
        Sentry.captureException(error);
      });
    }

    isRequesting.value = false;
  };

  const sendFeedback = async (feedback: string): Promise<void> => {
    if (!video.value || feedback.trim() === "") return;

    toast.removeAllGroups();

    isRequesting.value = true;

    try {
      video.value = await sendFullVideoFeedback(video.value.id, feedback);
    } catch (error) {
      displayError("There was an error sending your feedback. Please try again.");
      Sentry.withScope(function (scope) {
        scope.setTag("video_id", video.value?.id);
        scope.setTag("feedback", feedback);
        Sentry.captureException(error);
      });
    }

    isRequesting.value = false;
  };

  const update = async (data: Video): Promise<boolean> => {
    if (!video.value) return false;

    toast.removeAllGroups();

    isRequesting.value = true;

    // Don't wait for response, show changes right away
    video.value = {
      ...video.value,
      ...data,
    };

    let success = false;
    try {
      video.value = await updateVideo(video.value.id, data);
      displaySuccess("Changes saved successfully.");
      success = true;
    } catch (error) {
      displayError("There was an error updating the video. Please try again.");
      Sentry.withScope(function (scope) {
        scope.setTag("video_id", video.value?.id);
        Sentry.captureException(error);
      });
    }

    isRequesting.value = false;

    return success;
  };

  return {
    generateFull,
    generatePreview,
    isPortrait,
    isRequesting,
    loadVideo,
    loadingError,
    refresh,
    sendFeedback,
    statusIsError,
    statusIsGenerating,
    update,
    video,
  };
});
