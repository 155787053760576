import { defineStore } from "pinia";
import { ref } from "vue";

export const useCustomizeSidebarStore = defineStore("customizeSidebar", () => {
  const panelLook = ref<string | string[] | null | undefined>();
  const panelLyrics = ref<string | string[] | null | undefined>();
  const panelStory = ref<string | string[] | null | undefined>();

  const resetPanels = (): void => {
    panelLook.value = ["look", "format"];
    panelLyrics.value = "lyrics";
    panelStory.value = "story";
  };

  const setLookPanelStatus = (value: string | string[] | null | undefined): void => {
    panelLook.value = value;
  };

  const setLyricsPanelStatus = (value: string | string[] | null | undefined): void => {
    panelLyrics.value = value;
  };

  const setStoryPanelStatus = (value: string | string[] | null | undefined): void => {
    panelStory.value = value;
  };

  return {
    panelLook,
    panelLyrics,
    panelStory,
    resetPanels,
    setLookPanelStatus,
    setLyricsPanelStatus,
    setStoryPanelStatus,
  };
});
