import type { Video } from "@/api.d";

export const LOCAL_STORAGE_TTL = 604800 * 1000; // 7 days in milliseconds
export const LOCAL_STORAGE_KEY_VIDEO = "lvm-video";

export const VIDEO_STATUS_CLIPS_GENERATED = "clips_generated";
export const VIDEO_STATUS_ERROR = "error";
export const VIDEO_STATUS_IMAGES_GENERATED = "images_generated";
export const VIDEO_STATUS_LYRICS_EXTRACTED = "lyrics_extracted";
export const VIDEO_STATUS_PROMPTS_GENERATED = "prompts_generated";
export const VIDEO_STATUS_UNKNOWN = "unknown";
export const VIDEO_STATUS_UPLOADED = "uploaded";
export const VIDEO_STATUS_VIDEO_MESHED = "video_meshed";
export const VIDEO_STATUS_VIDEO_STATIC_MESHED = "video_static_meshed";

export const VIDEO_STATUSES = [
  VIDEO_STATUS_UPLOADED,
  VIDEO_STATUS_LYRICS_EXTRACTED,
  VIDEO_STATUS_PROMPTS_GENERATED,
  VIDEO_STATUS_IMAGES_GENERATED,
  VIDEO_STATUS_VIDEO_STATIC_MESHED,
  VIDEO_STATUS_CLIPS_GENERATED,
  VIDEO_STATUS_VIDEO_MESHED,
];

export const getVideoStatusIndex = (status: string): number => VIDEO_STATUSES.indexOf(status);

export const isVideoStatusDone = (status: string, targetStatus: string): boolean => {
  return getVideoStatusIndex(status) >= getVideoStatusIndex(targetStatus);
};

export const loadVideoData = (): Video | null => {
  const itemStr = localStorage.getItem(LOCAL_STORAGE_KEY_VIDEO);
  const item = itemStr && JSON.parse(itemStr);
  if (!item) {
    return null;
  }

  if (!item.ttl) {
    return item;
  }

  const now = new Date().getTime();
  if (now > item.ttl) {
    wipeVideoData();
    return null;
  }

  return item.data;
};

export const saveVideoData = (data: Video): void => {
  const ttl = new Date().getTime() + LOCAL_STORAGE_TTL;
  localStorage.setItem(LOCAL_STORAGE_KEY_VIDEO, JSON.stringify({ data, ttl }));
};

export const wipeVideoData = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_KEY_VIDEO);
};
