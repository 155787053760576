import "primeicons/primeicons.css";
import "video.js/dist/video-js.css";
import "./style.css";

import * as Sentry from "@sentry/vue";

import { createAuth0 } from "@auth0/auth0-vue";
import { definePreset } from "@primevue/themes";
import Aura from "@primevue/themes/aura";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import { createApp } from "vue";
import App from "./App.vue";
import CookieConsent from "./plugins/cookieconsent";
import PostHogPlugin from "./plugins/posthog";
import { createAppRouter } from "./router";

const primaryColor = "amber";
const variants = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950];
const LyricEditsPreset = definePreset(Aura, {
  // TODO: this is not working
  // primitive: {
  //     zinc: {
  //         ...Aura.primitive.zinc,
  //         950: "#121212",
  //     },
  // },
  semantic: {
    primary: variants.reduce(
      (obj, variant) => {
        obj[variant] = "{" + primaryColor + "." + variant + "}";
        return obj;
      },
      {} as Record<number, string>,
    ),
    colorScheme: {
      light: {
        // Keep colors same as dark mode
        primary: {
          color: "{primary.400}",
          contrastColor: "{surface.900}",
          hoverColor: "{primary.300}",
          activeColor: "{primary.200}",
        },
      },
    },
  },
});

const pinia = createPinia();
const app = createApp(App);

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
if (SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    integrations: [
      //   Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/(api\.)?lyricedits\.ai/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const POSTHOG_API_KEY = import.meta.env.VITE_POSTHOG_API_KEY;
if (POSTHOG_API_KEY) {
  app.use(PostHogPlugin, POSTHOG_API_KEY);
}

app.use(PrimeVue, {
  theme: {
    preset: LyricEditsPreset,
    options: {
      darkModeSelector: ".dark",
    },
  },
});

app.use(ConfirmationService);
app.use(ToastService);
app.use(CookieConsent);
app.use(createAppRouter(app));
app.use(pinia);
app.use(
  createAuth0({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    cacheLocation: "localstorage",
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: import.meta.env.VITE_AUTH0_REDIRECT_URL,
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    },
  }),
);

app.mount("#app");
